<template>
  <div class="box">
    <el-breadcrumb>
      <el-breadcrumb-item>系统设置</el-breadcrumb-item>
      <el-breadcrumb-item>操作日志</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <el-form label-width="120px" class="search-form" size="mini">
        <el-row style="margin-top: 20px;text-align: left;">
          <el-col :span="8">
            <el-form-item label="登陆时间" prop="name">
              <el-time-picker is-range v-model="queryInfo.condition.time" range-separator="至" start-placeholder="开始时间"
                end-placeholder="结束时间" placeholder="选择时间范围">
              </el-time-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="选择员工" prop="id">
              <el-select v-model="queryInfo.condition.id" placeholder="请选择">
                <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
              <!-- <el-input v-model="queryInfo.condition.phone" placeholder="请输入" clearable></el-input> -->
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6" :offset="18" style="display: flex; justify-content: flex-end;">
            <el-button type="primary" @click="search" icon="el-icon-search">查询</el-button>
            <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="tab">
      <el-table :data="tableData" style="width: 100%" height="calc(100vh - 440px)" border :header-cell-style="{
        'background-color': '#f2f2f2',
        'text-align': 'center',
        border: '1px solid #ddd',
      }">
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column prop="userName" label="名称" width="200" align="center">
        </el-table-column>
        <el-table-column prop="userCode" label="账号" width="100" align="center">
        </el-table-column>
        <el-table-column prop="operations" label="操作" align="center">
        </el-table-column>
        <el-table-column prop="createTime" label="时间" width="150" align="center">
        </el-table-column>

        <!-- <el-table-column prop=" " label="操作" width="150" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="reply(scope.row.id)">回复</el-button>
            <el-popconfirm confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-info" icon-color="red"
              title="确定的删除该条记录？" @onConfirm="del(scope.row.id)">
              <el-button type="text" slot="reference" style="color: red;">删除</el-button>
            </el-popconfirm>
          </template>
</el-table-column> -->
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      queryInfo: {
        condition: {
          id: '',
          name: ''
        },
        currPage: 1,
        pageCount: 1,
        pageSize: 10
      },
      total: 0,
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
    };
  },
  mounted() {
    this.getList()
    this.getEmplyeeList()
  },
  methods: {
    change(e) {
      console.log(e);
    },
    //获取数据
    getList() {
      var that = this;
      that.$http.post("/log/list", that.queryInfo).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },
    getEmplyeeList() {
      let _this = this
      let id = JSON.parse(localStorage.getItem('userInfo')).userInfo.id
      this.$http.post('/businessUser/queryList', { id: id }).then(function (response) {
        console.log("_+_+_+_+", response);
        if (response.data.code == 200) {
          _this.options = response.data.data
        }

      })
    },
    reply(id) {
      let _this = this
      this.id = id
      this.showDialog = true
      this.$http.post('/opinion/queryOne', { id: id }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          _this.feedbackObj = response.data.data
          console.log("_+_+_+_+", _this.feedbackObj);
        }
      })
    },
    del(id) {
      console.log("+++++++++++++++", id);

      this.id = id
      var _this = this;

      this.$http.post("/opinion/delete", {
        id: id
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          _this.$message({
            message: '删除成功',
            type: 'success'
          });
          _this.getList()
          // that.businessdetail = response.data.data
        }
      });

    },
    confirmBtn() {
      let _this = this
      this.$http.post('/opinion/update', this.feedbackObj).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          _this.$message({
            message: '已回复',
            type: 'success'
          });
          _this.showDialog = false
        }
      })
    },
    search() {
      this.queryInfo.currPage = 1;
      this.getList()
    },
    reset() {
      this.queryInfo.condition = {
        id: '',
        name: '',
      }
      this.queryInfo.currPage = 1;
      this.getList()
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList()
    },
  }
};
</script>

<style lang="less" scoped>
.box {
  .search {
    background-color: #fff;
    box-sizing: border-box;
    padding: 15px 15px;

    .search-top {
      padding: 5px;
      background-color: rgba(30, 152, 215, 0.14);
      font-size: 15px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      div {
        width: 150px;
        text-align: center;
      }
    }

    .search-form {
      padding-right: 50px;
    }
  }

  .tab {
    background-color: #fff;
    margin-top: 15px;
    padding: 15px
  }
}

/deep/ .el-switch__label * {
  font-size: 12px;
}

.dialogBox {
  position: relative;
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  position: absolute;
  top: -40px;
  left: 0;
}

.title>span:first-of-type {
  margin-right: 20px;
}

.same {
  color: rgb(138, 138, 138);
  // font-size: 10px;
}

.feedBackTop {
  border-top: 1px solid rgb(138, 138, 138);
  border-bottom: 1px solid rgb(138, 138, 138);
  padding: 20px;
}

.feedBackCenter {
  padding: 20px;
}

.sameBox {
  margin: 20px 0;
  display: flex;
}

.sameWidth {
  width: 70px;
}

.sameTextA {
  flex: 1;
}
</style>
